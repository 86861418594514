import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Icon } from "components/Icon";
import "./AliceCarousel.scss";

interface images {
  imageUrl: string;
  imageAltText: string;
  sequenceNumber: number;
  imageTitle: string;
}

interface IProps {  
  content: images[];
}
export default class Carousel extends React.Component<IProps> {
  Carousel: any;
  state = {
    carouselContent: this.props.content.sort((imageData1, imageData2) => imageData1.sequenceNumber - imageData2.sequenceNumber),
    carouselItems: this.props.content.map((i) => (
      <img key={`img_${i.sequenceNumber}`} src={i.imageUrl} alt={i.imageAltText} />
    )),
  };

  static getDerivedStateFromProps(props, currentState) {
    if(JSON.stringify(props.content) !== JSON.stringify(currentState.carouselContent)) {
      return {
        carouselItems: props.content.map((i) => (
          <><img key={`img_${i.sequenceNumber}`} src={i.imageUrl} alt={i.imageAltText} /><div className="image_title">{i.imageTitle}</div></>
        ))
      }
    }

    return null;
  }

  render() {
    return (
      <div className="carousel_wrapper" data-test="alice-carousel-wrapper">
        <AliceCarousel
          items={this.state.carouselItems}
          autoPlay
          autoPlayInterval={3500}
          disableButtonsControls={true}
          responsive={{
            0: {
              items: 1,
            },
          }}
          ref={(el) => (this.Carousel = el)}
          data-test="alice-carousel"
        />
        <button className="btn-prev" onClick={() => this.Carousel.slidePrev()}>
          <Icon iconName="icon-chevron-thick-left" />
        </button>
        <button className="btn-next" onClick={() => this.Carousel.slideNext()}>
          <Icon iconName="icon-chevron-thick-right" />
        </button>
      </div>
    );
  }
}
